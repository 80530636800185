import React, { useEffect, useRef, useState } from "react";

import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import cx from "classnames";
import styles from "./ConsentDetails.module.scss";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import { Alert, Modal, Typography, RadioGroup, Radio } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button } from "@mui/material";
import parse from "html-react-parser";
import SignatureCanvas from "react-signature-canvas";
import { isMobile } from "mobile-device-detect";
import { useNavigate } from "react-router-dom";
import Authenticate from "./Authenticate";
import { findIndex } from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import logo from "../assets/logo.png";
import ReactPlayer from "react-player";
import { Document, Page, pdfjs } from "react-pdf";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { toast } from "react-toastify";
import signatureImage from "./signature.jpg";
import draftToHtml from "draftjs-to-html";
import pdfConvert from "pdf-img-convert";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useQueryClient } from "@tanstack/react-query";
import CancelIcon from "@mui/icons-material/Cancel";

const steps = ["Consent Details", "Videos", "Consent form", "Fp17DC form"];

export default function ConsentDetails() {
  let { consentId } = useParams();
  const videoPlayerRef = useRef(null);
  let navigate = useNavigate();

  const [selectedVideo, setSelectedVideo] = React.useState<any>(null);
  const [selectedVideoPlayListIndex, setSelectedVideoPlayListIndex] =
    React.useState<any>(0);
  const [selectedVideoIndex, setSelectedVideoIndex] = React.useState<any>(0);
  const [playlistTabValue, setPlaylistTabValue] = React.useState(1);
  const [activeStep, setActiveStep] = React.useState(0);
  const [showSign, setShowSign] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [name, setName] = React.useState("");
  const [openAuthenticate, setOpenAuthenticate] = React.useState(true);
  const [isSignature, setIsSignature] = React.useState(false);
  const [currentVideoTime, setCurrentVideoTime] = React.useState(0);
  const [showWelcome, setShowWelcome] = React.useState(true);
  const [requirefp17dc, setRequirefp17dc] = React.useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState("");
  const [numPages, setNumPages] = React.useState(null);
  const [isNhsTreatmentSigned, setIsNhsTreatmentSigned] = React.useState(false);
  const [isPrvTreatmentSigned, setIsPrvTreatmentSigned] = React.useState(false);
  const [nhsChecked, setNhsChecked] = React.useState(false);
  const [prvChecked, setPrvChecked] = React.useState(false);
  const [isSucces, setIsSucces] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState("signature");
  const [consentBody, setConsentBody] = useState("");
  const [clickedButtonId, setClickedButtonId] = useState(null);
  const queryClient = useQueryClient();
  const [buttonColors, setButtonColors] = useState({});

  const [localDataSave, setLocalDataSave] = React.useState(false);
  const [clickedButtonIds, setClickedButtonIds] = useState<ClickedButton[]>([]);
  const [mandatoryButtonIds, setMandatoryButtonIds] = useState<string[]>([]);
  const [signtureUpdates, setSigntureUpdates] = useState<string[]>([]);
  const [isModalTriggered1, setisModalTriggered1] = React.useState(false);
  const [hasPlaceholder, sethasPlaceholder] = React.useState(false);
  const [isMandatory, setIsMandatory] = React.useState(false);

  const [yt, setYt] = React.useState(false);
  useEffect(() => {
    if (showSign) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [showSign]);

  const [playlistisnull, setPlaylistisnull] = useState(false);

  interface Signature {
    image: string;
    name: string;
    timestamp: string;
  }

  interface ClickedButton {
    id: string;
    signature: Signature;
  }

  const treatmentDetails = useQuery(
    ["treatmentDetails", consentId],
    async () => {
      const { data } = await axios.get(`/patient/consent_bundle/${consentId}`);
      return data;
    },
    {
      onSuccess: (data) => {
        setRequirefp17dc(data.require_fp17dc);
        let isPlaylistNull = false;

        if (!data.playlists || data.playlists.length === 0) {
          isPlaylistNull = true;
        } else {
          const allPlaylistsFalse = data.playlists.every((playlist) => {
            return playlist.status === false;
          });

          if (allPlaylistsFalse) {
            isPlaylistNull = true;
          } else {
            isPlaylistNull = false;

            // Check for YouTube URLs in playlist items
            data.playlists.forEach((playlist) => {
              if (
                playlist.playlist_items &&
                playlist.playlist_items.length > 0
              ) {
                const hasYouTubeUrls = playlist.playlist_items.some((item) => {
                  return item.url && item.url.includes("youtube.com");
                });

                if (hasYouTubeUrls) {
                  setYt(true);
                } else {
                  setYt(false);
                }
              } else {
                playlist.youtubeAvailable = false;
              }
            });
          }
        }

        setPlaylistisnull(isPlaylistNull);

        if (!isPlaylistNull) {
          const playListIndex = findIndex(data.playlists, { status: true });
          if (playListIndex > -1) {
            const videoIndex = findIndex(
              data.playlists[playListIndex]?.playlist_items,
              { status: true }
            );
            if (videoIndex > -1) {
              setSelectedVideoPlayListIndex(playListIndex);
              setPlaylistTabValue(playListIndex + 1);
              setSelectedVideo(
                data.playlists[playListIndex].playlist_items[videoIndex]
              );
              setSelectedVideoIndex(videoIndex);
            }
          }
        }

        if (data?.consent_form?.body) {
          let modifiedBody = data.consent_form.body;
          let buttonCount = 1;
          let hasPlaceholders = false;

          modifiedBody = modifiedBody.replace(
            /\[(signature_(optional|mandatory))\]/g, 
            (match, fullType, type) => {
              hasPlaceholders = true;
              const buttonId = `${type.toLowerCase()}-signature-btn-${buttonCount}`;
              const buttonText =
                type === "mandatory"
                  ? "Apply Signature Here *"
                  : "Apply Signature Here (Optional)";
                  
              const buttonHTML = `<button id="${buttonId}" class="${type.toLowerCase()}-signature-btn" style="${
                type === "mandatory"
                  ? "background-color: red; color: white;"
                  : ""
              }">${buttonText}</button>`;
          
              const unsignedButtonHTML = `
              <button 
                id="unsigned-${buttonId}" 
                class="unsigned-btn" 
                style="display: none; margin-left: 5px;" 
                title="Remove Signature">
                X
              </button>`;
          
        
              if (type === "mandatory") {
                setIsMandatory(true);
                setMandatoryButtonIds((prevIds) => [...prevIds, buttonId]);
              }
           

              buttonCount++;
              return buttonHTML + unsignedButtonHTML; 
            }
          );
          

          if (!hasPlaceholders) {
            sethasPlaceholder(true);
            console.log("No placeholders found in the modifiedBody.");
          }

          setConsentBody(modifiedBody);
          let isModalTriggered = false;

          document.body.addEventListener("click", (event) => {
            const targetButtonId = event.target.id;
           

            if (
              event.target.tagName.toLowerCase() === "button" &&
              (event.target.classList.contains("mandatory-signature-btn") ||
                event.target.classList.contains("optional-signature-btn"))
            ) {
              setClickedButtonId(targetButtonId);

              if (!localDataSave && !isModalTriggered) {
                handleModalOpen();
                isModalTriggered = true;
              } else if (!isModalTriggered) {
                // reuse signature logic

                const existingSignature = clickedButtonIds[0]?.signature;

                setClickedButtonIds((prevIds) => {
                 
                  const updatedIds = [
                    ...prevIds,
                    {
                      id: targetButtonId,
                      signature: existingSignature,
                    },
                  ];
                
                  setSigntureUpdates(updatedIds);
                 
                  return updatedIds;
                });

                if (existingSignature) {
                  const updatedIds = [
                    ...clickedButtonIds,
                    {
                      id: targetButtonId,
                      signature: existingSignature,
                    },
                  ];

                  //setClickedButtonIds(updatedIds);

                  setButtonColors((prevColors) => ({
                    ...prevColors,
                    [targetButtonId]: "green",
                  }));

                  const isMandatory = targetButtonId.includes("mandatory");

                  const buttonElement = document.getElementById(targetButtonId);
                  if (buttonElement) {
                    if (isMandatory) {
                      buttonElement.innerHTML = `Signature Applied <span style="color: red;">*</span>`;
                    } else {
                      buttonElement.innerText = "Signature Applied";
                    }
                  }

                  
                  const unsignedButton = document.getElementById(
                    `unsigned-${targetButtonId}`
                  );
                  if (unsignedButton) {
                    unsignedButton.style.display = "inline-block";
                  }
                }
              }
            } else if (event.target.classList.contains("unsigned-btn")) {
              const signatureIdToRemove = targetButtonId.replace(
                "unsigned-",
                ""
              );

              setClickedButtonIds((prevIds) =>
                prevIds.filter((item) => item.id !== signatureIdToRemove)
              );

              setSigntureUpdates((prevUpdates) =>
                prevUpdates.filter((item) => item.id !== signatureIdToRemove)
              );

              setButtonColors((prevColors) => ({
                ...prevColors,
                [signatureIdToRemove]: "orange",
              }));
            }
          });
        }
      },
      enabled: !openAuthenticate,
      refetchOnWindowFocus: false,
    }
  );

  const showNotification = ({ message, type, duration = 3000 }) => {
    const notification = document.createElement("div");

    const iconHTML =
      {
        info: "ℹ️",
        success: "✔️",
        error: "❌",
      }[type] || "ℹ️";

    notification.innerHTML = `
      <span style="font-weight: bold; margin-right: 8px;">${iconHTML}</span>
      <span style="font-weight: bold; color: black;">${message}</span>
    `;

    notification.style.position = "fixed";
    notification.style.top = "50%";
    notification.style.left = "50%";
    notification.style.transform = "translate(-50%, -50%)";
    notification.style.padding = "20px 30px";
    notification.style.borderRadius = "10px";
    notification.style.backgroundColor =
      type === "info" ? "#28a745" : type === "success" ? "#d4edda" : "#f8d7da";
    notification.style.color = "white";

    notification.style.zIndex = "1000";
    notification.style.textAlign = "center";
    notification.style.fontSize = "16px";

    document.body.appendChild(notification);

    setTimeout(() => {
      notification.remove();
    }, duration);
  };

  /// multiplae siganture --------
  const handleRemoveSignature = (buttonId) => {
    const isMandatory = buttonId.includes("mandatory");

    showNotification({
      message: "Removing signature...",
      type: "info",
      duration: 3000,
    });

    const buttonElement = document.getElementById(buttonId);
    if (buttonElement) {
      buttonElement.innerText = isMandatory
        ? "Apply Signature Here *"
        : "Apply Signature Here (Optional)";
    }

    // Hide the "Remove Signature" button
    const unsignedButton = document.getElementById(`unsigned-${buttonId}`);
    if (unsignedButton) {
      unsignedButton.style.display = "none";
    }

    // Optionally remove from the mandatory list if the button is mandatory
  };

  // add event listener to "Remove Signature" button
  document.addEventListener("click", (event) => {
    if (event.target && event.target.classList.contains("unsigned-btn")) {
      const buttonId = event.target.id.replace("unsigned-", "");
      handleRemoveSignature(buttonId);
    }
  });

  const handleSignatureSaveMultipale = () => {
    if (userFullName || mySignature.current) {
    
   
      const signatureData = mySignature.current
        ? mySignature.current.toDataURL()
        : "";
      const signatureName = userFullName || "";

      const signatureObject = {
        image: signatureData,
        name: signatureName,
      };

      if (clickedButtonId == null){
        if (signatureData && signatureData !== ""){
        
          handleSignatureSave();
        }else{
          handleNameSave();
        }
        return;
      }
    
      setClickedButtonIds((prevIds) => {
      
        const updatedIds = [
          ...prevIds,
          {
            id: clickedButtonId,
            signature: signatureObject,
          },
        ];
       
        setSigntureUpdates(updatedIds);
        return updatedIds;
      });

      setButtonColors((prevColors) => ({
        ...prevColors,
        [clickedButtonId]: "green",
      }));


      const isMandatory = clickedButtonId.includes("mandatory");

      const buttonElement = document.getElementById(clickedButtonId);
      if (buttonElement) {
        if (isMandatory) {
          buttonElement.innerHTML = `Signature Applied <span style="color: red;">*</span>`;
        } else {
          buttonElement.innerText = "Signature Applied";
        }
      }

      setLocalDataSave(true);

      setClickedButtonId(null);
      if (signatureObject.image || signatureObject.name) {
        setLocalDataSave(true);
      }

      handleModalClose();
      queryClient.invalidateQueries(["treatmentDetails", consentId]);

      const unsignedButton = document.getElementById(
        `unsigned-${clickedButtonId}`
      );
      if (unsignedButton) {
        unsignedButton.style.display = "inline-block";
      }
    }
    setisModalTriggered1(true);
  };

  const { mutate: sign } = useMutation((data) => {
    const uniqueButtonIds = [...new Set(mandatoryButtonIds)];
   
    const signatureIds = signtureUpdates.map((update) => update.id);
   

    const missingIds = uniqueButtonIds.filter( (id) => !signatureIds.includes(id));

    if (uniqueButtonIds.length === 0 && signatureIds.length === 0) {
      setIsModalOpen(true);
      setActiveInput("signature");
      return; 
  }

  
    if (missingIds.length > 0) {
      setShowBanner(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setShowBanner(false);
      const signatureData = signtureUpdates[0];
      const formattedData = {
        name: signatureData.signature.name,
        image: signatureData.signature.image === "" ? null : signatureData.signature.image,
        multiple_signature_data: JSON.stringify(
          signtureUpdates.map((update) => ({
            id: update.id,
            timestamp: Date.now(),
          }))
        ),
      };


    
      return axios
        .post(
          `/consent_bundle/${treatmentDetails.data.id}/upload_signature`,
          formattedData
        )
        .then((response) => {
          setIsFormSuccess(true);
          toast.success("Signed successfully");
        })
        .catch((error) => {
          console.error("Error uploading signature:", error);
        });
    }
  });

  const handleSignatureSave = () => {
    if (mySignature.current) {
      const signatureData = mySignature.current.toDataURL();
      signLegacy.mutate({ image: signatureData });
    }
    setActiveInput("");
    setIsImageVisible(true);
  };

  const handleNameSave = () => {
    signLegacy.mutate({ name: userFullName });
    setActiveInput("");
    setIsImageVisible(true);
  };

  const signLegacy = useMutation(
    ({ image, name }: { image?: string; name?: string }) => {
      const formattedData = {
        name: name,
        image: image,
        multiple_signature_data: JSON.stringify(
          signtureUpdates.map(() => ({
            id: "",
            timestamp: Date.now(),
          }))
        ),
      };
    
      return axios.post(
        `/consent_bundle/${treatmentDetails.data.id}/upload_signature`,
        formattedData
      );
    },
    {
      onSuccess: () => {
        window.scrollTo(0, 0);
        setIsModalOpen(false);
        setIsFormSuccess(true);
      },
    }
  );

  useEffect(() => {
    const buttons = document.querySelectorAll("button");
    buttons.forEach((button) => {
      const buttonId = button.id;
      if (buttonColors[buttonId]) {
        button.style.backgroundColor = buttonColors[buttonId];
      }
    });
  }, [buttonColors]);

  ///// multiplae siganture --------

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setActiveInput("signature");
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setActiveInput("signature");
    setUserFullName("");
    eraseSignature();
    setIsSignatureCaptured(false);
  };

  const [showBanner, setShowBanner] = useState(false);

  const markAsOpen = useMutation(() => {
    return axios.put(
      `/consent_bundle/${treatmentDetails.data.id}/mark_consent_bundle_as_opened`
    );
  });

  const addVideoLog = useMutation(
    (data: {
      type: string;
      timeStamp: number;
      videoId: string | undefined;
    }) => {
      if (data.videoId) {
        return axios.post(
          `/consent_bundle/${treatmentDetails.data.id}/video/${data.videoId}/log`,
          data
        );
      } else {
        return null;
      }
    }
  );

  const videoPlayerEvent = (videoId, e) => {
    addVideoLog.mutate({
      type: e.type,
      timeStamp: e.type === "seeking" ? currentVideoTime : e.target.currentTime,
      videoId,
    });
  };

  const savePauseEvent = () => {
    addVideoLog.mutate({
      type: "pause",
      timeStamp: currentVideoTime,
      videoId: selectedVideo?.id,
    });
  };

  const playNextVideo = () => {
    let playListIndex = selectedVideoPlayListIndex;
    const isLastVideo =
      treatmentDetails?.data.playlists[playListIndex].playlist_items.length ===
      selectedVideoIndex + 1;
    if (isLastVideo) {
      playListIndex = findIndex(
        treatmentDetails?.data.playlists,
        (v, index) => index > selectedVideoPlayListIndex && !!v.status
      );
    }
    if (playListIndex > -1) {
      let videoIndex = findIndex(
        treatmentDetails?.data.playlists[playListIndex]?.playlist_items,
        (v, index) => !!v.status
      );
      if (!isLastVideo) {
        videoIndex = findIndex(
          treatmentDetails?.data.playlists[playListIndex]?.playlist_items,
          (v, index) => index > selectedVideoIndex && !!v.status
        );
      }
      if (videoIndex > -1) {
        hackUpdate(
          treatmentDetails?.data.playlists[playListIndex].playlist_items[
            videoIndex
          ]
        );
        setSelectedVideoPlayListIndex(playListIndex);
        setSelectedVideoIndex(videoIndex);
        setPlaylistTabValue(playListIndex + 1);
      }
    }
  };

  const hackUpdate = (video) => {
    setSelectedVideo(null);
    setTimeout(() => {
      setSelectedVideo(video);
    }, 0);
  };

  const logData = useMutation((data: any) => {
    return axios.post(`/log`, data);
  });

  const nameRef = useRef("");

  const handleDone = (event) => {
    event.preventDefault();

    let data = {};

    if (selectedOption === "signature") {
      data = { image: mySignature.current.toDataURL() };
    } else if (selectedOption === "name") {
      data = { name: nameRef.current };
    }

    sign.mutate(data);
    setOpenModal(false);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleInputChange = (event) => {
    nameRef.current = event.target.value;
  };

  const handleFinishClick = async () => {
    try {
      await axios.put(
        `/patient/consent_bundle/${treatmentDetails.data.id}/complete_consent_bundle`
      );
      navigate(`/${treatmentDetails.data.id}/thankyou`);
    } catch (error) {
      console.error("Error completing consent bundle:", error);
    }
  };

  const [editorState, setEditorState] = React.useState<any>(
    EditorState.createEmpty()
  );

  const handleNhsTreatmentCheckbox = async (e) => {
    if (!nhsChecked) {
      try {
        const response = await axios.put(
          `/consent_bundle/${treatmentDetails.data.id}/mark_consent_bundle_as_signed_for_fp17dc_nhs_treatment`
        );

        setNhsChecked(true);
        setIsNhsTreatmentSigned(true);
      } catch (error) {
        console.error("Error marking NHS treatment as signed:", error);
      }
    }
  };

  const handlePrvTreatmentCheckbox = async (e) => {
    if (!prvChecked) {
      try {
        const response = await axios.put(
          `/consent_bundle/${treatmentDetails.data.id}/mark_consent_bundle_as_signed_for_fp17dc_private_treatment`
        );

        setPrvChecked(true);
        setIsPrvTreatmentSigned(true);
      } catch (error) {
        console.error("Error marking Private treatment as signed:", error);
      }
    }
  };

  const skipToNextVideo = () => {
    if (treatmentDetails?.data?.playlists) {
      const currentPlaylist =
        treatmentDetails.data.playlists[selectedVideoPlayListIndex];

      const nextIndex = selectedVideoIndex + 1;
      if (nextIndex >= currentPlaylist.playlist_items.length) {
        const nextPlaylistIndex = selectedVideoPlayListIndex + 1;
        if (nextPlaylistIndex < treatmentDetails.data.playlists.length) {
          const nextPlaylist =
            treatmentDetails.data.playlists[nextPlaylistIndex];
          if (nextPlaylist.playlist_items.length > 0) {
            setSelectedVideoPlayListIndex(nextPlaylistIndex);
            setSelectedVideoIndex(0);
            setSelectedVideo(nextPlaylist.playlist_items[0]);
            setPlaylistTabValue(nextPlaylistIndex + 1);
            logData.mutateAsync({
              type: "info",
              description: "Moved to the next playlist",
              event: "Patient Module Access",
              data: {
                consentBundleId: treatmentDetails.data.id,
                video: nextPlaylist.playlist_items[0].title,
              },
            });
          } else {
          }
        } else {
          console.log("No more playlists available.");
        }
      } else {
        // If there are more videos in the current playlist
        const nextVideo = currentPlaylist.playlist_items[nextIndex];
        setSelectedVideoIndex(nextIndex);
        setSelectedVideo(nextVideo);
        logData.mutateAsync({
          type: "info",
          description: "Skip to next video",
          event: "Patient Module Access",
          data: {
            consentBundleId: treatmentDetails.data.id,
            video: nextVideo.title,
          },
        });
      }
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeInput, setActiveInput] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const [isSignButtonEnabled, setIsSignButtonEnabled] = useState(false);
  const [isSignatureCaptured, setIsSignatureCaptured] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(true);
  const mySignature = React.useRef(null);
  const isMobile = window.innerWidth <= 768;

  const eraseSignature = () => {
    const signature = mySignature.current;
    setIsSignature(false);
    signature?.clear();
  };

  const handleChipClick = (inputType) => {
    setActiveInput(inputType);
    if (inputType === "signature") {
      setIsSignatureCaptured(false);
    }
  };

  const handleFullNameChange = (e) => setUserFullName(e.target.value);
  const [isLoadingViewPdf, setIsLoadingViewPdf] = React.useState(false);

  const PdfViewer = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const consentForm = draftToHtml(rawContentState);
    setIsLoadingViewPdf(true);
    axios({
      url: `${process.env.REACT_APP_BASE_URL}consent_bundle/${treatmentDetails.data.id}/generate_fp17dc_form_pdf`,
      method: "GET",
      responseType: "blob", // important
      data: { consentForm },
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      setPdfUrl(href);
      setIsLoadingViewPdf(false);
    });
  };

  const tabListRef = useRef(null);

  const handleTabChange = (e, v) => {
    setPlaylistTabValue(v);
    logData.mutateAsync({
      type: "info",
      description: "Click video playlist",
      event: "Patient Module Access",
      data: {
        consentBundleId: treatmentDetails.data.id,
        playlistId: treatmentDetails.data.playlists[v - 1].id,
      },
    });

    // Scroll the selected tab into view
    const tab = tabListRef.current.querySelector(`[data-value="${v}"]`);
    if (tab) {
      tab.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };

  const [selectedIssue, setSelectedIssue] = useState("");
  const [reason, setReason] = useState("");

  const [open, setOpen] = useState(false);
  const ReportIssueModalopen = () => setOpen(true);
  const ReportIssueModalclose = () => setOpen(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    setSelectedIssues((prev) =>
      checked ? [...prev, value] : prev.filter((issue) => issue !== value)
    );
  };

  return (
    <div className={styles.consentContainer}>
      <>
        {showBanner && (
          <div
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "10px",
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "15px",
            }}
          >
            Please sign all mandatory buttons before proceeding.
          </div>
        )}
      </>
      {openAuthenticate ? (
        <Authenticate
          openAuthenticate={openAuthenticate}
          setOpenAuthenticate={setOpenAuthenticate}
          consentId={consentId}
        />
      ) : (
        <>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {activeStep === 0 && (
            <div className={styles.consentSection}>
              <div className={styles.column}>
                <Box mb={3}>
                  <div className={styles.banner}>
                    <img src={logo} alt="Logo" className={styles.bannerLogo} />
                    <p className={styles.bannerText}>
                      Welcome to Medicube,
                      <br />
                      where your dentist has curated trusted content for your
                      review.
                    </p>
                  </div>
                </Box>

                <h2 className={styles.header}>Consent Details</h2>

                {treatmentDetails?.data?.patient && (
                  <div className={styles.column}>
                    <p className={styles.dataItem}>
                      Name: {treatmentDetails.data.patient.title}{" "}
                      {treatmentDetails.data.patient.first_name}{" "}
                      {treatmentDetails.data.patient.last_name}
                    </p>
                    <p className={styles.subDataItem}>
                      Email: {treatmentDetails.data.patient.email_address}
                    </p>
                    <p className={styles.subDataItem}>
                      Mobile: {treatmentDetails.data.patient.mobile_phone}
                    </p>
                    <p className={styles.subDataItem}>
                      Phone: {treatmentDetails.data.patient.home_phone}
                    </p>
                    <p className={styles.subDataItem}>
                      Address: {treatmentDetails.data.patient.address_line_1}{" "}
                      {treatmentDetails.data.patient.address_line_2}
                    </p>
                    <p className={styles.subDataItem}>
                      NHS Number: {treatmentDetails.data.patient.nhs_number}
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.footer}>
                <Button
                  variant="outlined"
                  color="secondary"
                  csize="large"
                  disabled
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (playlistisnull) {
                      setActiveStep(2);
                    } else {
                      markAsOpen.mutate();
                      logData.mutateAsync({
                        type: "info",
                        description:
                          "Click next button to move from Consent Details to Videos",
                        event: "Patient Module Access",
                        data: {
                          consentBundleId: treatmentDetails.data?.id,
                        },
                      });
                      setActiveStep(1);
                    }
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          {activeStep === 1 && (
            <div className={styles.consentSection}>
              {yt && (
                <div className={styles.banner}>
                  <p>
                    You may experience occasional issues while watching videos.
                    If you do, please let us know by clicking the report button.
                  </p>
                </div>
              )}
              <div className={styles.column}>
                <h2 className={styles.header}>Videos</h2>
              </div>
              {treatmentDetails?.data?.playlists?.length > 0 ? (
                <div className={styles.videos}>
                  <div className={styles.playListContainer}>
                    <TabContext value={playlistTabValue}>
                      <TabList
                        ref={tabListRef}
                        onChange={handleTabChange}
                        variant="scrollable"
                        className={styles.tabList}
                      >
                        {treatmentDetails?.data?.playlists?.map(
                          (list, index) => {
                            if (list.status) {
                              return (
                                <Tab
                                  label={list.title}
                                  value={index + 1}
                                  key={index}
                                  data-value={index + 1}
                                />
                              );
                            }
                            return null;
                          }
                        )}
                      </TabList>
                      {treatmentDetails?.data?.playlists?.map(
                        (playlist, playlistIndex) => {
                          if (playlist.status) {
                            return (
                              <TabPanel
                                className={styles.playList}
                                value={playlistIndex + 1}
                                key={playlistIndex}
                              >
                                {playlist?.playlist_items?.map(
                                  (video, index) => {
                                    if (video.status) {
                                      return (
                                        <div
                                          className={styles.video}
                                          key={index}
                                        >
                                          <Chip
                                            className={cx(styles.description, {
                                              [styles.active]:
                                                selectedVideoIndex === index,
                                            })}
                                            label={video.title}
                                            avatar={
                                              <img
                                                className={styles.thumbnail}
                                                src={video.thumbnail}
                                                alt={video.title}
                                              />
                                            }
                                            variant="outlined"
                                            onClick={() => {
                                              savePauseEvent();
                                              hackUpdate(video);
                                              setSelectedVideoPlayListIndex(
                                                playlistIndex
                                              );
                                              setSelectedVideoIndex(index);
                                              logData.mutateAsync({
                                                type: "info",
                                                description:
                                                  "Click video thumbnail",
                                                event: "Patient Module Access",
                                                data: {
                                                  consentBundleId:
                                                    treatmentDetails.data.id,
                                                  video: video.title,
                                                },
                                              });
                                            }}
                                          />
                                        </div>
                                      );
                                    }
                                    return null;
                                  }
                                )}
                              </TabPanel>
                            );
                          } else {
                            return null;
                          }
                        }
                      )}
                    </TabContext>
                    <Box mt={4} ml={5}>
                      <div className={styles.buttonRow}>
                        <Button
                          variant="contained"
                          color="info"
                          onClick={skipToNextVideo}
                          endIcon={<SkipNextIcon />}
                        >
                          Skip Video
                        </Button>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "red", color: "white" }}
                          endIcon={<SkipNextIcon />}
                          onClick={ReportIssueModalopen}
                        >
                          Report
                        </Button>

                        <Modal open={open} onClose={ReportIssueModalclose}>
                          <Box
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              bgcolor: "white",
                              padding: 4,
                              borderRadius: 2,
                              boxShadow: 24,
                              width: 400,
                            }}
                          >
                            <Typography variant="h6" gutterBottom>
                              Report an Issue
                            </Typography>

                            <RadioGroup
                              value={selectedIssue}
                              onChange={(e) => setSelectedIssue(e.target.value)}
                            >
                              <FormControlLabel
                                value="The video is playing too slowly."
                                control={<Radio />}
                                label="The video is playing too slowly."
                              />
                              <FormControlLabel
                                value="The video is not loading at all."
                                control={<Radio />}
                                label="The video is not loading at all."
                              />
                              <FormControlLabel
                                value="The video quality is poor."
                                control={<Radio />}
                                label="The video quality is poor."
                              />
                              <FormControlLabel
                                value="I’m being asked to sign in to confirm I’m not a bot."
                                control={<Radio />}
                                label="I’m being asked to sign in to confirm I’m not a bot."
                              />
                            </RadioGroup>

                            <div
                              style={{
                                marginTop: 16,
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button onClick={ReportIssueModalclose}>
                                Close
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={async () => {
                                  if (selectedIssue) {
                                    try {
                                      const response = await axios.post(
                                        `/consent_bundle/${treatmentDetails.data.id}/video/${selectedVideo.id}/log_video_playback_issue`,
                                        {
                                          video_issue: selectedIssue,
                                        }
                                      );
                                      console.log(
                                        "Report sent successfully:",
                                        response
                                      );
                                      ReportIssueModalclose();
                                    } catch (error) {
                                      console.error(
                                        "Error sending report:",
                                        error
                                      );
                                      alert(
                                        "An error occurred while reporting the issue."
                                      );
                                    }
                                  } else {
                                    alert("Please select an issue.");
                                  }
                                }}
                                disabled={!selectedIssue}
                              >
                                Send
                              </Button>
                            </div>
                          </Box>
                        </Modal>
                      </div>
                    </Box>
                  </div>

                  <div className={styles.player}>
                    {selectedVideo && (
                      <>
                        <ReactPlayer
                          url={selectedVideo?.url}
                          controls
                          playing={false}
                          onPause={() =>
                            videoPlayerEvent(selectedVideo?.id, {
                              type: "pause",
                            })
                          }
                          onPlay={() =>
                            videoPlayerEvent(selectedVideo?.id, {
                              type: "play",
                            })
                          }
                          onSeek={(seekTime) =>
                            videoPlayerEvent(selectedVideo?.id, { seekTime })
                          }
                          onEnded={() => {
                            playNextVideo();
                            videoPlayerEvent(selectedVideo?.id, {
                              type: "ended",
                            });
                          }}
                          onProgress={({ playedSeconds }) =>
                            setCurrentVideoTime(playedSeconds)
                          }
                          width="100%"
                          height="480px"
                        />
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <p className={styles.noVideos}>
                  Your dentist hasn’t assigned any videos for your viewing
                </p>
              )}
              <div className={styles.footer}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    savePauseEvent();
                    setActiveStep(0);
                    logData.mutateAsync({
                      type: "info",
                      description:
                        "Click back button to move from Videos to Consent Details",
                      event: "Patient Module Access",
                      data: {
                        consentBundleId: treatmentDetails.data.id,
                      },
                    });
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    savePauseEvent();
                    setActiveStep(2);
                    logData.mutateAsync({
                      type: "info",
                      description:
                        "Click next button to move from Videos to Consent form",
                      event: "Patient Module Access",
                      data: {
                        consentBundleId: treatmentDetails.data.id,
                      },
                    });
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          )}

          {activeStep === 2 && (
            <div className={styles.consentSection}>
              <div className={styles.row}>
                <div className={styles.column}>
                  <div className={styles.consentForm}>
                    {consentBody
                      ? parse(consentBody)
                      : "Consent form body not available."}
                  </div>
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={() => setChecked((val) => !val)}
                      />
                    }
                    label="I agree to sign this document electronically"
                  />

                  {!hasPlaceholder && isMandatory === false ? (
                    <Button
                      variant="contained"
                      color={isFormSuccess ? "success" : "warning"}
                      size="large"
                      onClick={() => sign()}
                      disabled={
                        sign.isLoading || !checked || (isMobile && !checked)
                      }
                    >
                      {isFormSuccess ? "Signed" : "Sign"}
                    </Button>
                  ) : hasPlaceholder ? (
                    <Button
                      variant="contained"
                      color={isFormSuccess ? "success" : "warning"}
                      size="large"
                      onClick={() => handleModalOpen()}
                      disabled={
                        sign.isLoading || !checked || (isMobile && !checked)
                      }
                    >
                      {isFormSuccess ? "Signed" : "Sign"}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color={isFormSuccess ? "success" : "warning"}
                      size="large"
                      disabled={!checked}
                      onClick={() => sign()}
                    >
                      {isFormSuccess ? "Signed" : "Sign"}
                    </Button>
                  )}
                </div>
              </div>

              <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="modal-signature"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "90%", sm: "600px" },
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <h2 id="modal-signature">Sign Consent</h2>

                  {isMobile ? (
                    <div
                      className={styles.signPad}
                      style={{
                        width: "100%",
                        border: "2px solid #243474",
                        margin: "10px 0",
                        padding: "10px",
                        borderRadius: "8px",
                        boxSizing: "border-box",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <SignatureCanvas
                        penColor="#243474"
                        ref={mySignature}
                        canvasProps={{
                          className: styles.signCanvas,
                          width: Math.min(window.innerWidth * 0.9, 400),
                          height: Math.min(window.innerWidth * 0.9 * 0.5, 300),
                        }}
                        onBegin={() => {
                          setIsSignatureCaptured(true);
                          setIsImageVisible(false);
                        }}
                      />

                      {isImageVisible && (
                        <img
                          src={signatureImage}
                          alt="Draw Here"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "50%",
                            maxWidth: "200px",
                            height: "auto",
                            opacity: 0.5,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      <Chip
                        label="Signature"
                        variant={
                          activeInput === "signature" ? "filled" : "outlined"
                        }
                        onClick={() => handleChipClick("signature")}
                        sx={{
                          mr: 2,
                          fontSize: "1.2rem",
                          padding: "10px 20px",
                          backgroundColor:
                            activeInput === "signature"
                              ? "#FFA500"
                              : "transparent",
                          color:
                            activeInput === "signature" ? "white" : "inherit",
                        }}
                      />
                      <Chip
                        label="Enter Name"
                        variant={activeInput === "name" ? "filled" : "outlined"}
                        onClick={() => handleChipClick("name")}
                        sx={{
                          fontSize: "1.2rem",
                          padding: "10px 20px",
                          backgroundColor:
                            activeInput === "name" ? "#FFA500" : "transparent",
                          color: activeInput === "name" ? "white" : "inherit",
                        }}
                      />

                      {activeInput === "signature" && (
                        <div
                          className={styles.signPad}
                          style={{
                            border: "2px solid #243474",
                            margin: "10px 0",
                            padding: "10px",
                            borderRadius: "8px",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <SignatureCanvas
                            penColor="#243474"
                            ref={mySignature}
                            canvasProps={{
                              className: styles.signCanvas,
                              width: 600,
                              height: 400,
                            }}
                            onBegin={() => {
                              setIsSignatureCaptured(true);
                              setIsImageVisible(false);
                            }}
                          />

                          {isImageVisible && (
                            <img
                              src={signatureImage}
                              alt="Draw Here"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "50%",
                                maxWidth: "200px",
                                height: "auto",
                                opacity: 0.5,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                        </div>
                      )}

                      {activeInput === "name" && (
                        <>
                          <TextField
                            label="Full Name"
                            variant="outlined"
                            fullWidth
                            value={userFullName}
                            onChange={(e) => setUserFullName(e.target.value)}
                            sx={{ mt: 2 }}
                          />
                        </>
                      )}
                    </>
                  )}

                  {/* Button Container */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setIsModalOpen(false)}
                      sx={{ alignSelf: "flex-end" }}
                    >
                      Close
                    </Button>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      {activeInput === "signature" && (
                        <>
                          <IconButton
                            aria-label="erase"
                            onClick={eraseSignature}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <Button
                            variant="contained"
                            color="success"
                            size="large"
                            onClick={
                              hasPlaceholder
                                ? handleSignatureSave
                                : handleSignatureSaveMultipale
                            }
                            disabled={!isSignatureCaptured}
                            sx={{ ml: 2 }}
                          >
                            Save Signature
                          </Button>
                        </>
                      )}

                      {activeInput === "name" && (
                        <Button
                          variant="contained"
                          color="success"
                          size="large"
                          onClick={
                            hasPlaceholder
                              ? handleSignatureSave
                              : handleSignatureSaveMultipale
                          }
                          disabled={!userFullName}
                          sx={{ ml: 2 }}
                        >
                          Save Name
                        </Button>
                      )}
                    </div>
                  </div>
                </Box>
              </Modal>

              <div className={styles.footer}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    if (playlistisnull) {
                      setActiveStep(0);
                    } else {
                      setActiveStep(1);
                    }

                    // Log the action
                    logData.mutateAsync({
                      type: "info",
                      description: `Click back button to move from ${
                        !playlistisnull
                          ? "Consent Form to Overview"
                          : "Consent Form to Videos"
                      }`,
                      event: "Patient Module Access",
                      data: {
                        consentBundleId: treatmentDetails.data.id,
                      },
                    });
                  }}
                >
                  Back
                </Button>

                {isFormSuccess &&
                  (requirefp17dc ? (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => {
                        PdfViewer();
                        savePauseEvent();
                        setActiveStep(3);
                        logData.mutateAsync({
                          type: "info",
                          description:
                            "Click next button to move from Consent form to new step",
                          event: "Patient Module Access",
                          data: { consentBundleId: treatmentDetails.data.id },
                        });
                      }}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleFinishClick}
                    >
                      Finish
                    </Button>
                  ))}
              </div>
            </div>
          )}

          {activeStep === 3 && (
            <div className={styles.consentSection}>
              <div className={styles.row}>
                <div className={styles.column}>
                  <h2 className={styles.header}>FP17DC Form</h2>
                  {pdfUrl && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {isLoadingViewPdf && (
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                          <CircularProgress /> <p>Loading FP17DC form...</p>
                        </div>
                      )}

                      {pdfUrl && isMobile ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <embed
                            src={`${pdfUrl}#toolbar=0`}
                            style={{
                              width: "100%",
                              height: "400px",
                              border: "none",
                              overflow: "auto",
                            }}
                            type="application/pdf"
                            title="PDF Viewer"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "200%",
                            height: "900px",
                            display: "flex",
                            justifyContent: "center",
                            overflow: "auto",
                          }}
                        >
                          {pdfUrl && (
                            <iframe
                              src={`${pdfUrl}#toolbar=0`}
                              style={{
                                width: "220%",
                                height: "2500px",
                                border: "none",
                                overflow: "scroll",
                                display: "block",
                                pointerEvents: "none",
                              }}
                              title="PDF Viewer"
                              scrolling="yes"
                            />
                          )}
                        </div>
                      )}

                      <div
                        style={{
                          marginTop: "20px",
                          padding: "10px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: isMobile
                              ? "flex-start"
                              : "space-between",
                            gap: "15px",
                          }}
                        >
                          {/* NHS Treatment Toggle */}
                          <label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "16px",
                              gap: "10px",
                              cursor: "pointer",
                              flex: 1,
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={nhsChecked}
                              onChange={handleNhsTreatmentCheckbox}
                              style={{ display: "none" }}
                            />
                            <span
                              style={{
                                width: "250px",
                                height: "50px",
                                backgroundColor: nhsChecked
                                  ? "#007bff"
                                  : "#ff5722",
                                borderRadius: "25px",
                                position: "relative",
                                transition: "background-color 0.3s",
                                display: "inline-block",
                                textAlign: "center",
                                lineHeight: "50px",
                                color: "white",
                                fontWeight: "bold",
                                cursor: nhsChecked ? "default" : "pointer",
                              }}
                              onClick={handleNhsTreatmentCheckbox}
                            >
                              {nhsChecked
                                ? "NHS Signed"
                                : "Apply Signature for NHS"}
                            </span>
                          </label>

                          {/* Private Treatment Toggle */}
                          <label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "16px",
                              gap: "10px",
                              cursor: "pointer",
                              flex: 1,
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={prvChecked}
                              onChange={handlePrvTreatmentCheckbox}
                              style={{ display: "none" }}
                            />
                            <span
                              style={{
                                width: "250px",
                                height: "50px",
                                backgroundColor: prvChecked
                                  ? "#007bff"
                                  : "#ff5722",
                                borderRadius: "25px",
                                position: "relative",
                                transition: "background-color 0.3s",
                                display: "inline-block",
                                textAlign: "center",
                                lineHeight: "50px",
                                color: "white",
                                fontWeight: "bold",
                                cursor: prvChecked ? "default" : "pointer",
                              }}
                              onClick={handlePrvTreatmentCheckbox}
                            >
                              {prvChecked
                                ? "Private Signed"
                                : "Apply Signature for Private"}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.footer}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    setActiveStep(2);
                    logData.mutateAsync({
                      type: "info",
                      description:
                        "Click back button to move from new step to Consent Form",
                      event: "Patient Module Access",
                      data: { consentBundleId: treatmentDetails.data.id },
                    });
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleFinishClick}
                  disabled={!isNhsTreatmentSigned}
                  style={{
                    backgroundColor: isNhsTreatmentSigned
                      ? "#28a745"
                      : "#6c757d",
                    color: "white",
                    borderColor: isNhsTreatmentSigned ? "#28a745" : "#6c757d",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  Finish
                </Button>
              </div>
            </div>
          )}

          {showSign && (
            <div className={styles.signPad}>
              <SignatureCanvas
                penColor="#243474"
                ref={mySignature}
                canvasProps={{
                  className: isSignature
                    ? styles.signCanvas
                    : styles.signCanvasBackground,
                }}
                onBegin={() => setIsSignature(true)}
              />
              <IconButton
                aria-label="close"
                className={styles.close}
                onClick={() => {
                  setShowSign(false);
                }}
              >
                <CloseIcon />
              </IconButton>
              <div className={styles.signFooter}>
                <IconButton aria-label="erase" onClick={() => eraseSignature()}>
                  <DeleteIcon />
                </IconButton>
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  onClick={
                    hasPlaceholder
                      ? handleSignatureSave
                      : handleSignatureSaveMultipale
                  }
                  disabled={!isSignatureCaptured}
                  sx={{ ml: 2 }}
                >
                  Save Signature
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
